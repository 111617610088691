var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "maca-modal",
        {
          ref: "modal",
          attrs: {
            titulo: "Datos de Punto de Venta",
            "solicitar-confirmacion-close": false
          }
        },
        [
          _vm.cargando
            ? _c("span", [_vm._v("Cargando...")])
            : _c("div", [
                _vm.datos
                  ? _c("table", { staticClass: "tabla-info" }, [
                      _c("tbody", [
                        _c("tr", [
                          _c("th", [_vm._v("Nombre")]),
                          _c("td", {
                            domProps: { textContent: _vm._s(_vm.datos.nombre) }
                          })
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Canal")]),
                          _c("td", {
                            domProps: {
                              textContent: _vm._s(_vm.datos.tipoCanal)
                            }
                          })
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Empleado")]),
                          _c("td", {
                            domProps: {
                              textContent: _vm._s(_vm.datos.empleado)
                            }
                          })
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Provincia")]),
                          _c("td", {
                            domProps: {
                              textContent: _vm._s(_vm.datos.provincia)
                            }
                          })
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Localidad")]),
                          _c("td", {
                            domProps: {
                              textContent: _vm._s(_vm.datos.localidad)
                            }
                          })
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Barrio")]),
                          _c("td", {
                            domProps: { textContent: _vm._s(_vm.datos.barrio) }
                          })
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Domicilio")]),
                          _c("td", {
                            domProps: {
                              textContent: _vm._s(_vm.datos.domicilio)
                            }
                          })
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Es Secco")]),
                          _c("td", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.datos.compradorSecco ? "Si" : "No"
                              )
                            }
                          })
                        ]),
                        _c(
                          "tr",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.datos.razonNoCompradorSecco != null,
                                expression:
                                  "datos.razonNoCompradorSecco != null"
                              }
                            ]
                          },
                          [
                            _c("th", [_vm._v("Razón No Comprador Secco")]),
                            _c("td", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.datos.razonNoCompradorSecco
                                )
                              }
                            })
                          ]
                        ),
                        _c(
                          "tr",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.datos.tipoCompradorSecco != null,
                                expression: "datos.tipoCompradorSecco != null"
                              }
                            ]
                          },
                          [
                            _c("th", [_vm._v("Tipo Comprador Secco")]),
                            _c(
                              "td",
                              _vm._l(_vm.datos.tipoCompradorSecco, function(
                                item,
                                index
                              ) {
                                return _c("span", { key: index }, [
                                  _c("span", [_vm._v(_vm._s(item.nombre))]),
                                  index !=
                                  _vm.datos.tipoCompradorSecco.length - 1
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: { "margin-right": "7px" }
                                        },
                                        [_vm._v(",")]
                                      )
                                    : _vm._e()
                                ])
                              }),
                              0
                            )
                          ]
                        ),
                        _c("tr", [
                          _c("th", [_vm._v("Tipo Distribuidor")]),
                          _c("td", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.datos.tipoCompraPuntoVenta
                              )
                            }
                          })
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Tipo Extra")]),
                          _c(
                            "td",
                            _vm._l(_vm.datos.puntoVentaTipoExtra, function(
                              item,
                              index
                            ) {
                              return _c("span", { key: index }, [
                                _c("span", [_vm._v(_vm._s(item.nombre))]),
                                index !=
                                _vm.datos.puntoVentaTipoExtra.length - 1
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-right": "7px" }
                                      },
                                      [_vm._v(",")]
                                    )
                                  : _vm._e()
                              ])
                            }),
                            0
                          )
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Visitado")]),
                          _c("td", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.datos.contactarRealizado ? "Si" : "No"
                              )
                            }
                          })
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("A Visitar")]),
                          _c("td", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.datos.contactar ? "Si" : "No"
                              )
                            }
                          })
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Contacto Nombre")]),
                          _c("td", {
                            domProps: {
                              textContent: _vm._s(_vm.datos.duenioNombre)
                            }
                          })
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Contacto Teléfono")]),
                          _c("td", {
                            domProps: {
                              textContent: _vm._s(_vm.datos.duenioTelefono)
                            }
                          })
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Distribuidor")]),
                          _c("td", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.datos.contactarDistribuidorMayorista
                              )
                            }
                          })
                        ]),
                        _vm.datos && _vm.datos.urlImagen == null
                          ? _c("tr", [
                              _c("th", [_vm._v("Foto")]),
                              _c("td", [_vm._v("Sin Foto")])
                            ])
                          : _vm._e()
                      ])
                    ])
                  : _vm._e(),
                _vm.datos && _vm.datos.urlImagen != null
                  ? _c(
                      "div",
                      { staticStyle: { "margin-top": "20px" } },
                      [
                        _c(
                          "el-link",
                          {
                            attrs: {
                              href: _vm.datos.urlImagen,
                              target: "_blank"
                            }
                          },
                          [
                            _c("el-image", {
                              staticStyle: { width: "100%", height: "auto" },
                              attrs: {
                                src: _vm.datos.urlImagen,
                                fit: "contain"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }