var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "maca-modal",
        {
          ref: "modal",
          attrs: {
            titulo: "Nuevo Punto de Venta",
            impedirClose: _vm.impedirClose
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.formRules,
                "label-width": "150px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Nombre", prop: "nombre" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.nombre,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "nombre", $$v)
                      },
                      expression: "form.nombre"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Barrio", prop: "barrio" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.barrio,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "barrio", $$v)
                      },
                      expression: "form.barrio"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Domicilio", prop: "domicilio" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.domicilio,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "domicilio", $$v)
                      },
                      expression: "form.domicilio"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Canal", prop: "tipoCanal" } },
                [
                  _c("maca-select-box", {
                    attrs: {
                      icon: "el-icon-folder-opened",
                      url: _vm.urlTipoCanal
                    },
                    model: {
                      value: _vm.form.tipoCanal,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "tipoCanal", $$v)
                      },
                      expression: "form.tipoCanal"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Latitud", prop: "latitud" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.latitud,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "latitud", $$v)
                      },
                      expression: "form.latitud"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Longitud", prop: "longitud" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.longitud,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "longitud", $$v)
                      },
                      expression: "form.longitud"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Tipo", prop: "tipoCompraPuntoVenta" } },
                [
                  _c("maca-select-box", {
                    attrs: {
                      icon: "el-icon-folder-opened",
                      url: _vm.urlTipoCompraPuntoVenta
                    },
                    model: {
                      value: _vm.form.tipoCompraPuntoVenta,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "tipoCompraPuntoVenta", $$v)
                      },
                      expression: "form.tipoCompraPuntoVenta"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "Tipo Extra", prop: "tipoExtraPuntoVentas" }
                },
                [
                  _c("maca-select-box", {
                    attrs: {
                      icon: "el-icon-s-flag",
                      url: _vm.urltipoExtraPuntoVenta,
                      multiple: ""
                    },
                    model: {
                      value: _vm.form.tipoExtraPuntoVentas,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "tipoExtraPuntoVentas", $$v)
                      },
                      expression: "form.tipoExtraPuntoVentas"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "A Visitar", prop: "contactar" } },
                [
                  _c("el-checkbox", {
                    attrs: { indeterminate: false },
                    model: {
                      value: _vm.form.contactar,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "contactar", $$v)
                      },
                      expression: "form.contactar"
                    }
                  })
                ],
                1
              ),
              _vm.form.contactar
                ? _c(
                    "el-form-item",
                    {
                      attrs: { label: "Contacto Nombre", prop: "duenoNombre" }
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.duenoNombre,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "duenoNombre", $$v)
                          },
                          expression: "form.duenoNombre"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.contactar
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Contacto Teléfono",
                        prop: "duenoTelefono"
                      }
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.duenoTelefono,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "duenoTelefono", $$v)
                          },
                          expression: "form.duenoTelefono"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.contactar
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Distribuidor",
                        prop: "distribuidorMayorista"
                      }
                    },
                    [
                      _c("maca-select-box", {
                        attrs: {
                          icon: "el-icon-folder-opened",
                          url: _vm.urlDistribuidorMayorista
                        },
                        model: {
                          value: _vm.form.distribuidorMayorista,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "distribuidorMayorista", $$v)
                          },
                          expression: "form.distribuidorMayorista"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                [
                  _c("maca-boton-guardar", {
                    attrs: { onSubmit: _vm.onSubmit }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }