<template>
  <div>
    <maca-modal titulo="Datos de Punto de Venta" :solicitar-confirmacion-close="false" ref="modal">
      <span v-if="cargando">Cargando...</span>
      <div v-else>
        <table v-if="datos" class="tabla-info">
          <tbody>
            <tr>
              <th>Nombre</th>
              <td v-text="datos.nombre"></td>
            </tr>
            <tr>
              <th>Canal</th>
              <td v-text="datos.tipoCanal"></td>
            </tr>
            <tr>
              <th>Empleado</th>
              <td v-text="datos.empleado"></td>
            </tr>
            <tr>
              <th>Provincia</th>
              <td v-text="datos.provincia"></td>
            </tr>
            <tr>
              <th>Localidad</th>
              <td v-text="datos.localidad"></td>
            </tr>
            <tr>
              <th>Barrio</th>
              <td v-text="datos.barrio"></td>
            </tr>
            <tr>
              <th>Domicilio</th>
              <td v-text="datos.domicilio"></td>
            </tr>
            <tr>
              <th>Es Secco</th>
              <td v-text="datos.compradorSecco ? 'Si' : 'No'"></td>
            </tr>
            <tr v-show="datos.razonNoCompradorSecco != null">
              <th>Razón No Comprador Secco</th>
              <td v-text="datos.razonNoCompradorSecco"></td>
            </tr>
            <tr v-show="datos.tipoCompradorSecco != null">
              <th>Tipo Comprador Secco</th>
              <td>
                <span v-for="(item, index) in datos.tipoCompradorSecco" :key="index">
                  <span>{{item.nombre}}</span>
                  <span
                      v-if="index != datos.tipoCompradorSecco.length - 1"
                      style="margin-right: 7px"
                  >,</span>
                </span>
              </td>
            </tr>
            <tr>
              <th>Tipo Distribuidor</th>
              <td v-text="datos.tipoCompraPuntoVenta"></td>
            </tr>
            <tr>
              <th>Tipo Extra</th>
              <td>
                <span v-for="(item, index) in datos.puntoVentaTipoExtra" :key="index">
                  <span>{{item.nombre}}</span>
                  <span
                    v-if="index != datos.puntoVentaTipoExtra.length - 1"
                    style="margin-right: 7px"
                  >,</span>
                </span>
              </td>
            </tr>
            <tr>
              <th>Visitado</th>
              <td v-text="datos.contactarRealizado ? 'Si' : 'No'"></td>
            </tr>
            <tr>
              <th>A Visitar</th>
              <td v-text="datos.contactar ? 'Si' : 'No'"></td>
            </tr>
            <tr>
              <th>Contacto Nombre</th>
              <td v-text="datos.duenioNombre"></td>
            </tr>
            <tr>
              <th>Contacto Teléfono</th>
              <td v-text="datos.duenioTelefono"></td>
            </tr>
            <tr>
              <th>Distribuidor</th>
              <td v-text="datos.contactarDistribuidorMayorista"></td>
            </tr>
            <tr v-if="datos && datos.urlImagen == null">
              <th>Foto</th>
              <td>Sin Foto</td>
            </tr>
          </tbody>
        </table>
        <div v-if="datos && datos.urlImagen != null" style="margin-top: 20px;">
          <el-link :href="datos.urlImagen" target="_blank">
            <el-image style="width: 100%; height: auto" :src="datos.urlImagen" fit="contain"></el-image>
          </el-link>
        </div>
      </div>
    </maca-modal>
  </div>
</template>

<script>
export default {
  name: "ver",
  data() {
    return {
      id: null,
      datos: null,
      cargando: false,
    };
  },
  methods: {
    abrir(id) {
      this.id = id;
      this.$refs.modal.abrir();
      this.getDatos();
    },
    async getDatos() {
      this.cargando = true;

      let url = this.$api.URL + "/puntoVenta/obtenerDatos?puntoVentaID=" + this.id;

      let respuestaApi = await this.$api.get(url, this.$usuarioToken());

      this.cargando = false;

      if (respuestaApi != null) {
        this.datos = respuestaApi;
      }
    },
  },
};
</script>
