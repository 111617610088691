<template>
  <div>
    <div class="contenedor-titulo">
      <div class="titulo">
        <h3><i class="el-icon-s-flag"></i>Puntos de Venta</h3>
      </div>
      <div class="botones">
        <el-button
          type="default"
          @click="descargarExcel"
          v-loading="cargandoExcel"
          v-if="!$usuarioEs.supervisor()"
          >Exportar Puntos A Visitar</el-button
        >
      </div>
    </div>
    <el-card :body-style="{ padding: '20px' }">
      <el-tabs v-model="pestanaActiva" @tab-click="handleClick">
        <el-tab-pane label="Tabla" name="0">
          <vista-tabla
            @filtroNombreCambiado="(val) => (filtroNombre = val)"
            @filtroTipoCanalCambiado="(val) => (filtroTipoCanal = val)"
            @filtroVisitadoCambiado="(val) => (filtroVisitado = val)"
            @filtroFechasCambiado="
              (val) => {
                filtroDesde = val[0];
                filtroHasta = val[1];
              }
            "
          ></vista-tabla>
        </el-tab-pane>
        <el-tab-pane label="Mapa" name="1">
          <!--          <el-button
            v-if="!creandoNuevo"
            type="text"
            icon="el-icon-plus"
            @click="creandoNuevo = true"
          >Nuevo Punto de Venta</el-button>
          <el-button v-else type="text" @click="creandoNuevo = false">Cancelar</el-button>-->
          <mapa-puntos-venta
            ref="mapa"
            :class="{ creandoNuevo: creandoNuevo }"
            style="width: 100%; height: 600px"
            @mapClicked="mapClicked"
          ></mapa-puntos-venta>
        </el-tab-pane>
      </el-tabs>
    </el-card>
    <modal-ver ref="modalVer"></modal-ver>
    <modal-nuevo ref="modalNuevo" @actualizar-tabla="getDatos"></modal-nuevo>
    <modal-modificar
      ref="modalModificar"
      @actualizar-tabla="getDatos"
    ></modal-modificar>
  </div>
</template>

<style lang="css">
.creandoNuevo {
  border: 1px solid red;
}

.creandoNuevo .leaflet-grab {
  cursor: crosshair;
}
</style>

<script>
import VistaTabla from "./tabla";
import ModalVer from "./modales/ver";
import ModalNuevo from "./modales/nuevo";
import ModalModificar from "./modales/modificar";
import MapaPuntosVenta from "./mapa";
import GeneradorExcelTabla from "./generar_excel.js";

export default {
  name: "puntoVenta",
  components: {
    VistaTabla,
    ModalVer,
    ModalNuevo,
    ModalModificar,
    MapaPuntosVenta,
  },
  data() {
    return {
      cargando: false,
      creandoNuevo: false,
      pestanaActiva: "0",
      cargandoExcel: false,

      filtroNombre: null,
      filtroTipoCanal: null,
      filtroVisitado: null,
      filtroDesde: null,
      filtroHasta: null,
    };
  },
  mounted() {},
  methods: {
    handleClick(tab, event) {
      if (tab.index === "1") {
        this.pestanaActiva = "1";

        this.$refs.mapa.permitirActualizacionAuto = true;
        this.$refs.mapa.redrawMap();
        this.$refs.mapa.getDatos();
      }
    },
    getDatos() {
      //
    },
    mapClicked(data) {
      if (this.creandoNuevo) {
        this.creandoNuevo = false;
        this.$refs.modalNuevo.abrir(data.latlng);
      }
    },
    async descargarExcel() {
      let url = this.$api.URL + "/puntoVenta/obtenerTodosReporte";
      let separador = "?";

      if (this.filtroNombre != null && this.filtroNombre != "") {
        url = url + separador + "nombre=" + this.filtroNombre;
        separador = "&";
      }
      if (this.filtroTipoCanal != null) {
        url = url + separador + "tipoCanalID=" + this.filtroTipoCanal.id;
        separador = "&";
      }
      if (this.filtroDesde != null) {
        url = url + separador + "fechaDesde=" + this.filtroDesde;
        separador = "&";
      }
      if (this.filtroHasta != null) {
        url = url + separador + "fechaHasta=" + this.filtroHasta;
        separador = "&";
      }

      // Varaible para el excel, para convertir
      // el valor del filtro en algo lejible para la persona
      let filtroVisitadoLejible = "";

      if (this.filtroVisitado != null) {
        if (this.filtroVisitado == "AV") {
          url = url + separador + "contactar=1";
          separador = "&";
          filtroVisitadoLejible = "A Visitar (Todos)";
        } else if (this.filtroVisitado == "AVNV") {
          url = url + separador + "contactar=1&contactarRealizado=0";
          separador = "&";
          filtroVisitadoLejible = "A Visitar (Sólo No Visitados)";
        } else {
          url = url + separador + "contactarRealizado=1";
          separador = "&";
          filtroVisitadoLejible = "Visitados";
        }
      }

      this.cargandoExcel = true;

      this.$message({
        message: "Procesando datos. Por favor espere hasta obtener el Excel.",
        type: "info",
        icon: "el-icon-info",
        showClose: true,
        duration: 0,
      });

      let respuestaApi = await this.$api.get(url, this.$usuarioToken(), 0);

      this.cargandoExcel = false;

      GeneradorExcelTabla.run(
        //form
        {
          nombreReporte: "Puntos de Venta",
          filtroNombre: this.filtroNombre,
          filtroTipoCanal: this.filtroTipoCanal,
          filtroVisitado: filtroVisitadoLejible,
        },
        // data
        respuestaApi.datos
      );
    },
  },
};
</script>
