<template>
  <div>
    <maca-modal titulo="Nuevo Punto de Venta" :impedirClose="impedirClose" ref="modal">
      <el-form :model="form" ref="form" :rules="formRules" label-width="150px">
        <el-form-item label="Nombre" prop="nombre">
          <el-input v-model="form.nombre"></el-input>
        </el-form-item>
        <el-form-item label="Barrio" prop="barrio">
          <el-input v-model="form.barrio"></el-input>
        </el-form-item>
        <el-form-item label="Domicilio" prop="domicilio">
          <el-input v-model="form.domicilio"></el-input>
        </el-form-item>
        <el-form-item label="Canal" prop="tipoCanal">
          <maca-select-box
            icon="el-icon-folder-opened"
            v-model="form.tipoCanal"
            :url="urlTipoCanal"
          ></maca-select-box>
        </el-form-item>
        <el-form-item label="Latitud" prop="latitud">
          <el-input v-model="form.latitud" disabled></el-input>
        </el-form-item>
        <el-form-item label="Longitud" prop="longitud">
          <el-input v-model="form.longitud" disabled></el-input>
        </el-form-item>
        <el-form-item label="Tipo" prop="tipoCompraPuntoVenta">
          <maca-select-box
            icon="el-icon-folder-opened"
            v-model="form.tipoCompraPuntoVenta"
            :url="urlTipoCompraPuntoVenta"
          ></maca-select-box>
        </el-form-item>
        <el-form-item label="Tipo Extra" prop="tipoExtraPuntoVentas">
          <maca-select-box
            icon="el-icon-s-flag"
            v-model="form.tipoExtraPuntoVentas"
            :url="urltipoExtraPuntoVenta"
            multiple
          ></maca-select-box>
        </el-form-item>
        <el-form-item label="A Visitar" prop="contactar">
          <el-checkbox v-model="form.contactar" :indeterminate="false"></el-checkbox>
        </el-form-item>
        <el-form-item v-if="form.contactar" label="Contacto Nombre" prop="duenoNombre">
          <el-input v-model="form.duenoNombre"></el-input>
        </el-form-item>
        <el-form-item v-if="form.contactar" label="Contacto Teléfono" prop="duenoTelefono">
          <el-input v-model="form.duenoTelefono"></el-input>
        </el-form-item>
        <el-form-item v-if="form.contactar" label="Distribuidor" prop="distribuidorMayorista">
          <maca-select-box
            icon="el-icon-folder-opened"
            v-model="form.distribuidorMayorista"
            :url="urlDistribuidorMayorista"
          ></maca-select-box>
        </el-form-item>

        <el-form-item>
          <maca-boton-guardar :onSubmit="onSubmit"></maca-boton-guardar>
        </el-form-item>
      </el-form>
    </maca-modal>
  </div>
</template>

<script>
export default {
  name: "nuevo-puntoVenta",
  data() {
    return {
      form: {
        nombre: "",
        barrio: "",
        domicilio: "",
        latitud: "",
        longitud: "",
        tipoCanal: null,
        contactar: false,
        tipoCompraPuntoVenta: null,
        distribuidorMayorista: null,
        duenoNombre: "",
        duenoTelefono: "",
        tipoExtraPuntoVentas: [],
      },
      impedirClose: false,

      urlTipoCanal: this.$api.URL + "/tipoCanal/obtenerTodosSelect",
      urlTipoCompraPuntoVenta:
        this.$api.URL + "/tipoCompraPuntoVenta/obtenerTodosSelect",
      urlDistribuidorMayorista:
        this.$api.URL + "/distribuidorMayorista/obtenerTodosSelect",
      urltipoExtraPuntoVenta:
        this.$api.URL + "/tipoExtraPuntoVenta/obtenerTodosSelect",
    };
  },
  computed: {
    formRules() {
      let result = {
        nombre: [
          {
            required: true,
            message: "Por favor introduzca el nombre.",
            trigger: "change",
          },
        ],
        barrio: [
          {
            required: true,
            message: "Por favor introduzca el barrio.",
            trigger: "change",
          },
        ],
        tipoCanal: [
          {
            required: true,
            message: "Por favor introduzca un canal.",
            trigger: "change",
          },
        ],
        domicilio: [
          {
            required: true,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],
        latitud: [
          {
            required: true,
            message: "Por favor introduzca la latitud.",
            trigger: "change",
          },
        ],
        longitud: [
          {
            required: true,
            message: "Por favor introduzca la longitud.",
            trigger: "change",
          },
        ],
      };

      if (this.form.contactar) {
        result.duenoNombre = [
          {
            required: true,
            message:
              "Por favor introduzca un nombre de contacto para la visita.",
            trigger: "change",
          },
        ];
        result.duenoTelefono = [
          {
            required: true,
            message:
              "Por favor introduzca un número de teléfono para la visita.",
            trigger: "change",
          },
        ];
        result.distribuidorMayorista = [
          {
            required: true,
            message: "Por favor introduzca un distribuidor para la visita.",
            trigger: "change",
          },
        ];
      }

      return result;
    },
  },
  methods: {
    abrir(latlng) {
      this.$refs.modal.abrir();

      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }

      this.form.latitud = latlng.lat;
      this.form.longitud = latlng.lng;
    },
    cerrar() {
      this.$refs.modal.cerrar();
    },
    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          afterSubmit();
          return false;
        }

        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;
        afterSubmit();

        if (postOk) {
          this.$emit(this.$eventStrings._actualizarTabla);
          this.cerrar();
        }
      });
    },
    async postApi() {
      // Hacer Post
      let params = {
        nombre: this.form.nombre,
        barrio: this.form.barrio,
        domicilio: this.form.domicilio,
        latitud: this.form.latitud,
        longitud: this.form.longitud,
        tipoCanalID: this.form.tipoCanal.id,
        cargaApi: "1",
        contactar: this.form.contactar ? 1 : 0,
        tipoCompraPuntoVentaID: this.form.tipoCompraPuntoVenta.id,
        duenioNombre: this.form.duenoNombre,
        duenioTelefono: this.form.duenoTelefono,
      };

      // convertir arreglo de extras a algo que la
      // api entienda
      let tipoExtraPuntoVentaAPI = [];
      this.form.tipoExtraPuntoVentas.forEach((element) => {
        tipoExtraPuntoVentaAPI.push({
          id: 0,
          tipoExtraPuntoVentaID: element.id,
        });
      });
      params.tipoExtraPuntoVenta = JSON.stringify(tipoExtraPuntoVentaAPI);
      //

      if (this.form.distribuidorMayorista != null) {
        params.contactarDistribuidorMayoristaID = this.form.distribuidorMayorista.id;
      }

      let respuestaApi = await this.$api.post(
        this.$api.URL + "/puntoVenta/crear",
        params,
        this.$usuarioToken()
      );
      //

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Punto de Venta creado con éxito!",
          type: "success",
        });

        return true;
      } else if (respuestaApi.estado === 0) {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0
        });
      } else {
        this.$message({
          message: respuestaApi.mensaje,
          type: "error",
          showClose: true,
          duration: 0
        });
      }

      return false;
    },
  },
};
</script>

