import XLSX from "xlsx";
import moment from "moment";

function run(form, datos) {
  /* original data */
  var filename = form.nombreReporte + ".xlsx";
  var data = [];
  var ws_name = "Hoja 1";

  if (datos == null) {
    return;
  }

  data.push(["Reporte de " + form.nombreReporte]);
  data.push([]);

  if (form.filtroNombre !== null) {
    data.push([]);
    data.push(["Buscando por nombre:", form.filtroNombre]);
  }
  if (form.filtroTipoCanal !== null) {
    data.push([]);
    data.push(["De Tipo de Canal:", form.filtroTipoCanal.nombre]);
  }
  if (form.filtroVisitado !== null) {
    data.push([]);
    data.push(["De Empleado:", form.filtroVisitado]);
  }

  data.push([]);
  data.push([]);

  let cabezera = [
    "Fecha",
    "Nombre",
    "Canal",
    "Empleado",
    "Barrio",
    "Domicilio",
    "Localidad",
    "Provincia",
    "Tipo Distribuidor",
    "Tiene Pinturas",
    "Tiene Heladeras",
    "Tiene Carteles",
    "Tiene Punteras",
    "Visitado",
    "A Visitar",
    "Contacto Nombre",
    "Contacto Teléfono",
    "Distribuidor",
  ];

  data.push(cabezera);
  datos.forEach((fila) => {
    let tieneCarteles = "No";
    let tieneHeladeras = "No";
    let tienePinturas = "No";
    let tienePunteras = "No";

    if (fila.puntoVentaTipoExtra != null) {
      fila.puntoVentaTipoExtra.forEach((element) => {
        if (element.nombre == "Carteles") {
          tieneCarteles = "Si";
        } else if (element.nombre == "Heladeras") {
          tieneHeladeras = "Si";
        } else if (element.nombre == "Pinturas") {
          tienePinturas = "Si";
        } else if (element.nombre == "Punteras") {
          tienePunteras = "Si";
        }
      });
    }

    data.push([
      formatearFecha(fila.creado),
      fila.nombre,
      fila.tipoCanal,
      fila.empleado,
      fila.barrio,
      fila.domicilio,
      fila.localidad,
      fila.provincia,
      fila.tipoCompraPuntoVenta,
      tieneCarteles,
      tieneHeladeras,
      tienePinturas,
      tienePunteras,
      fila.contactarRealizado ? "Si" : "No",
      fila.contactar ? "Si" : "No",
      fila.duenioNombre,
      fila.duenioTelefono,
      fila.contactarDistribuidorMayorista,
    ]);
  });

  var wb = XLSX.utils.book_new(),
    ws = XLSX.utils.aoa_to_sheet(data);

  /* add worksheet to workbook */
  XLSX.utils.book_append_sheet(wb, ws, ws_name);

  /* write workbook */
  XLSX.writeFile(wb, filename);
}

// Dada una fecha que moment pueda procesar,
// devolver una fecha con formato DD/MM/YYYY
function formatearFechaMes(fecha) {
  if (fecha == "" || fecha == null) {
    return "";
  }

  let date = moment(fecha);
  if (!date.isValid()) {
    date = moment(fecha, "YYYY-MM-DD");
  }

  if (!date.isValid()) {
    return "";
  }

  return date.format("MMMM YYYY");
}

// Dada una fecha que moment pueda procesar,
// devolver una fecha con formato DD/MM/YYYY
function formatearFecha(fecha) {
  if (fecha == "" || fecha == null) {
    return "";
  }

  let date = moment(fecha);
  if (!date.isValid()) {
    date = moment(fecha, "YYYY-MM-DD");
  }

  if (!date.isValid()) {
    return "";
  }

  return date.format("DD/MM/YYYY");
}

// Dada una hora de formato hh:mm:ss, devolver hh:mm
function formatearHora(hora) {
  if (hora == null) {
    return;
  }
  return hora.substr(0, 5);
}

function getDireccion(detallePuntoVenta) {
  let val = "";

  let calle =
    detallePuntoVenta.domicilioCalle == null
      ? ""
      : detallePuntoVenta.domicilioCalle;
  let numero =
    detallePuntoVenta.domicilioNro == null
      ? ""
      : detallePuntoVenta.domicilioNro;
  let localidad =
    detallePuntoVenta.domicilioLocalidad == null
      ? ""
      : detallePuntoVenta.domicilioLocalidad;
  let zona =
    detallePuntoVenta.domicilioZona == null
      ? ""
      : detallePuntoVenta.domicilioZona;
  let cp =
    detallePuntoVenta.domicilioCodigoPostal == null
      ? ""
      : detallePuntoVenta.domicilioCodigoPostal;

  val = calle + " " + numero + "," + localidad + " " + zona + " (" + cp + ")";

  if (val.trim() == ",  ()") {
    return "Sin Asignar";
  } else {
    return val;
  }
}

export default {
  run,
};
