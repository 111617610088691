<template>
  <div>
    <div>
      <maca-datatable
        :url="urlTabla"
        :params="paramsTabla"
        :actualizar.sync="actualizarTabla"
        :bloquear.sync="bloquearTabla"
      >
        <el-table-column label="Fecha" :width="100">
          <template slot="header">
            <maca-datatable-filtrar-rangofecha
              label="Fecha"
              v-model="filtroFechas"
            ></maca-datatable-filtrar-rangofecha>
          </template>
          <template slot-scope="props">
            <span v-text="$common.formatearFecha(props.row.creado)"></span>
          </template>
        </el-table-column>
        <el-table-column label="Foto" width="70px">
          <template slot-scope="props">
            <el-link :href="props.row.urlImagen" target="_blank">
              <el-image
                style="width: 50px; height: 50px"
                :src="props.row.urlImagen"
                fit="contain"
              >
                <div slot="error" class="image-slot">Sin Foto</div>
              </el-image>
            </el-link>
          </template>
        </el-table-column>
        <el-table-column label="Nombre" :width="150">
          <template slot="header">
            <maca-datatable-filtrar
              label="Nombre"
              v-model="filtroNombre"
            ></maca-datatable-filtrar>
          </template>
          <template slot-scope="props">
            <span v-text="props.row.nombre"></span>
          </template>
        </el-table-column>
        <el-table-column label="Teléfono" :width="120">
          <template slot-scope="props">
            <span
              v-if="props.row.duenioTelefono"
              v-text="props.row.duenioTelefono"
            ></span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="Canal" :width="150">
          <template slot="header">
            <maca-datatable-filtrar-select
              label="Canal"
              v-model="filtroTipoCanal"
              :urlSelect="urlTipoCanal"
            ></maca-datatable-filtrar-select>
          </template>
          <template slot-scope="props">
            <span v-text="props.row.tipoCanal"></span>
          </template>
        </el-table-column>
        <el-table-column label="Empleado">
          <template slot-scope="props">
            <span v-text="props.row.empleado"></span>
          </template>
        </el-table-column>
        <el-table-column label="Provincia">
          <template slot="header">
            <maca-datatable-filtrar-select
              label="Provincia"
              v-model="filtroProvincia"
              :urlSelect="urlProvincia"
            ></maca-datatable-filtrar-select>
          </template>
          <template slot-scope="props">
            <span v-text="props.row.provincia"></span>
          </template>
        </el-table-column>
        <el-table-column label="Localidad">
          <template slot-scope="props">
            <span v-text="props.row.localidad"></span>
          </template>
        </el-table-column>
        <el-table-column label="Barrio">
          <template slot-scope="props">
            <span v-text="props.row.barrio"></span>
          </template>
        </el-table-column>
        <el-table-column label="Es Secco" :width="110">
          <template slot="header">
            <maca-datatable-filtrar-sino
              label="Es Secco"
              v-model="filtroCompradorSecco"
            ></maca-datatable-filtrar-sino>
          </template>
          <template slot-scope="props">
            <b v-if="props.row.compradorSecco" style="color: #ec2127">Si</b>
            <span v-else>No</span>
          </template>
        </el-table-column>
        <el-table-column label="Visitado" :width="130">
          <template slot="header">
            <maca-datatable-filtrar-visitado
              label="Visitado"
              v-model="filtroVisitado"
            ></maca-datatable-filtrar-visitado>
          </template>
          <template slot-scope="props">
            <div style="display: flex">
              <el-tooltip
                class="item"
                effect="dark"
                content="Registrar que ya fue visitado"
                placement="bottom-start"
              >
                <el-button
                  style="margin: auto"
                  v-if="!props.row.contactarRealizado && props.row.contactar"
                  type="success"
                  size="small"
                  plain
                  round
                  icon="el-icon-check"
                  @click="marcarVisitado(props.row.id)"
                ></el-button>
              </el-tooltip>
              <el-button
                style="margin: auto; font-size: 20px"
                v-if="props.row.contactarRealizado"
                type="text"
                size="small"
                icon="el-icon-check"
              ></el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Ver" width="70">
          <template slot-scope="props">
            <el-button
              type="success"
              size="small"
              @click="$refs.modalVer.abrir(props.row.id)"
              plain
              round
            >
              <i class="el-icon-view"></i>
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
          v-if="!$usuarioEs.supervisor()"
          label="Editar"
          width="70"
        >
          <template slot-scope="props">
            <el-button
              type="warning"
              size="small"
              @click="$refs.modalModificar.abrir(props.row.id)"
              plain
              round
            >
              <i class="el-icon-edit"></i>
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
          v-if="!$usuarioEs.supervisor()"
          label="Borrar"
          width="70"
        >
          <template slot-scope="props">
            <el-button
              type="danger"
              size="small"
              @click="eliminar(props.row.id)"
              plain
              round
            >
              <i class="el-icon-delete"></i>
            </el-button>
          </template>
        </el-table-column>
      </maca-datatable>
    </div>
    <modal-ver
      ref="modalVer"
      @actualizar-tabla="actualizarTabla = true"
    ></modal-ver>
    <modal-modificar
      ref="modalModificar"
      @actualizar-tabla="actualizarTabla = true"
    ></modal-modificar>
  </div>
</template>

<style></style>

<style scoped></style>

<script>
import ModalVer from "./modales/ver";
import ModalModificar from "./modales/modificar";
import MacaDatatableFiltrarVisitado from "./filtrar_visitado";
import MacaDatatableFiltrarSino from "../relevamientos/filtrar_sino";

export default {
  name: "home",
  components: {
    ModalVer,
    ModalModificar,
    MacaDatatableFiltrarVisitado,
    MacaDatatableFiltrarSino,
  },
  data() {
    return {
      urlTabla: this.$api.URL + "/puntoVenta/obtenerTodos",
      paramsTabla: [],
      actualizarTabla: true,
      bloquearTabla: true,

      urlTipoCanal: this.$api.URL + "/tipoCanal/obtenerTodosSelect",
      urlProvincia: this.$api.URL + "/provincia/obtenerTodos",

      filtroNombre: null,
      filtroTipoCanal: null,
      filtroProvincia: null,
      filtroVisitado: null,
      filtroCompradorSecco: null,
      filtroFechas: null,
    };
  },
  methods: {
    async eliminar(id) {
      let confirmado = await this.$confirm(
        "Confirme que desea eliminar el punto de venta."
      );

      if (!confirmado) {
        return;
      }

      this.bloquearTabla = true;

      // Hacer post
      let params = { puntoVentaID: id };

      let respuestaApi = await this.$api.post(
        this.$api.URL + "/puntoVenta/eliminar",
        params,
        this.$usuarioToken()
      );
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Punto de Venta borrado con éxito!",
          type: "success",
        });
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
    async marcarVisitado(id) {
      let confirmado = await this.$confirm(
        "Confirme que desea marcar el punto de venta como visitado."
      );

      if (!confirmado) {
        return;
      }

      this.bloquearTabla = true;

      // Hacer post
      let params = { puntoVentaID: id };

      let respuestaApi = await this.$api.post(
        this.$api.URL + "/puntoVenta/actualizarContactado",
        params,
        this.$usuarioToken()
      );
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Punto de Venta marcado como contactado con éxito!",
          type: "success",
        });
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
  },
  watch: {
    filtroNombre() {
      if (this.filtroNombre != null) {
        this.$common.agregarUrlParam(
          this.paramsTabla,
          "nombre",
          this.filtroNombre
        );
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "nombre");
      }

      this.actualizarTabla = true;
      this.$emit("filtroNombreCambiado", this.filtroNombre);
    },
    filtroTipoCanal() {
      if (this.filtroTipoCanal != null) {
        this.$common.agregarUrlParam(
          this.paramsTabla,
          "tipoCanalID",
          this.filtroTipoCanal.id
        );
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "tipoCanalID");
      }

      this.actualizarTabla = true;
      this.$emit("filtroTipoCanalCambiado", this.filtroTipoCanal);
    },
    filtroProvincia() {
      if (this.filtroProvincia != null) {
        this.$common.agregarUrlParam(
          this.paramsTabla,
          "provinciaID",
          this.filtroProvincia.id
        );
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "provinciaID");
      }

      this.actualizarTabla = true;
      this.$emit("filtroProvinciaCambiado", this.filtroProvincia);
    },
    filtroVisitado() {
      if (this.filtroVisitado != null) {
        if (this.filtroVisitado == "AV") {
          this.$common.agregarUrlParam(this.paramsTabla, "contactar", 1);
          this.$common.sacarUrlParam(this.paramsTabla, "contactarRealizado");
        } else if (this.filtroVisitado == "AVNV") {
          this.$common.agregarUrlParam(this.paramsTabla, "contactar", 1);
          this.$common.agregarUrlParam(
            this.paramsTabla,
            "contactarRealizado",
            0
          );
        } else {
          this.$common.sacarUrlParam(this.paramsTabla, "contactar");
          this.$common.agregarUrlParam(
            this.paramsTabla,
            "contactarRealizado",
            1
          );
        }
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "contactar");
        this.$common.sacarUrlParam(this.paramsTabla, "contactarRealizado");
      }

      this.$emit("filtroVisitadoCambiado", this.filtroVisitado);
      this.actualizarTabla = true;
    },
    filtroCompradorSecco() {
      if (this.filtroCompradorSecco != null) {
        this.$common.agregarUrlParam(
          this.paramsTabla,
          "compradorSecco",
          this.filtroCompradorSecco
        );
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "compradorSecco");
      }

      this.actualizarTabla = true;
    },
    filtroFechas() {
      if (this.filtroFechas != null) {
        this.$common.agregarUrlParam(
          this.paramsTabla,
          "fechaDesde",
          this.filtroFechas[0]
        );
        this.$common.agregarUrlParam(
          this.paramsTabla,
          "fechaHasta",
          this.filtroFechas[1]
        );
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "fechaDesde");
        this.$common.sacarUrlParam(this.paramsTabla, "fechaHasta");
      }

      this.actualizarTabla = true;
      this.$emit("filtroFechasCambiado", [
        this.filtroFechas[0],
        this.filtroFechas[1],
      ]);
    },
  },
};
</script>
