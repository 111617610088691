<template>
  <div>
    <div :style="{ height: mapData.height + 'px', width: '100%' }">
      <l-map
        ref="map"
        :zoom="mapData.zoom"
        :center="mapData.center"
        v-loading="cargando"
        @click="data => $emit('mapClicked', data)"
      >
        <l-tile-layer :url="mapData.url"></l-tile-layer>
        <l-marker-cluster>
          <l-marker
            ref="marcador"
            v-for="(item, index) in markers"
            :key="'marcador' + index"
            :id="'marcador' + index"
            :lat-lng="item.latlng"
            :icon="getClaseMarcador(item)"
            @click="$refs.modalVer.abrir(item.id)"
          ></l-marker>
        </l-marker-cluster>
        <l-control position="topleft">
          <div class="mapControlContainer">
            <div class="titulo">
              <h6>Filtros</h6>
              <el-button
                type="text"
                @click="
                  filtrosControlVisible
                    ? (filtrosControlVisible = false)
                    : (filtrosControlVisible = true)
                "
                style="width: 50px"
                icon="el-icon-arrow-down"
              ></el-button>
            </div>
            <div style="margin-top: 10px" v-if="filtrosControlVisible">
              <b>Provincia</b>
              <maca-select-box
                  placeholder="Provincia"
                  v-model="provincia"
                  :url="urlProvincia"
                  clearable
                  multiple
                  @change="
                  () => {
                    provinciaCambiada();
                    getDatos();
                  }
                "
                  :soloIniciales="
                  $usuarioEs.operador() || $usuarioEs.supervisor()
                "
              ></maca-select-box>
              <br />
              <div v-if="provincia != null" :key="keyLocalidad">
                <b>Localidad</b>
                <el-select
                  style="margin-top: 10px"
                  v-model="localidad"
                  placeholder="Localidad"
                  multiple
                  @change="getDatos"
                >
                  <el-option
                    v-for="item in listaLocalidad"
                    :key="item.id"
                    :label="item.nombre + ' (' + item.provincia + ')'"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </div>
              <br />
              <b>Marcas</b>
              <maca-select-box
                placeholder="Marcas"
                v-model="marcas"
                :url="urlMarca"
                clearable
                multiple
                @change="() => {getDatos();}"
              ></maca-select-box>
            </div>
          </div>
        </l-control>
        <l-control position="bottomleft">
          <slot name="bottomLeft"></slot>
        </l-control>
      </l-map>
    </div>
    <modal-ver ref="modalVer" @actualizar-tabla="actualizarTabla = true"></modal-ver>
  </div>
</template>

<style lang="css">
.mapControlContainer {
  background: #ffffff88;
  height: auto;
  width: auto;
  border-radius: 10px;
  padding: 10px;
}

.mapControlContainer > .titulo {
  display: flex;
  height: 40px;
}
.mapControlContainer > .titulo > h6 {
  margin: auto;
  margin-left: 0px;
}

.leaflet-marker-icon.marker-cluster {
  display: flex;
}

.leaflet-marker-icon.marker-cluster > div {
  margin: auto;
  min-width: 30px;
  display: flex;
  background: #4bf1ff78;
  padding: 5px;
  border-radius: 100%;
  border: 1px solid #004fffcc;
  box-shadow: #0005 1px 2px 2px 1px;
}

.leaflet-marker-icon.marker-cluster > div > * {
  margin: auto;
  font-weight: bold;
}

.pinturas {
  filter: hue-rotate(0deg);
}
.carteles {
  filter: hue-rotate(75deg);
}
.heladeras {
  filter: hue-rotate(180deg);
}
.punteras {
  filter: hue-rotate(275deg);
}
</style>

<script>
import ModalVer from "./modales/ver";

export default {
  name: "home",
  components: { ModalVer },
  data() {
    return {
      mapData: {
        url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
        zoom: 4,
        center: [-37.2411793, -62.6314284],
        height: window.innerHeight - 50, // restar la barra de navegacion,
      },

      urlProvincia: this.$api.URL + "/provincia/obtenerTodos?paisID=1",
      urlLocalidad: this.$api.URL + "/localidad/obtenerTodos?provinciaID=1",

      provincia: [],
      localidad: null,
      markers: [],
      datosRelevamientos: null,
      filtrosControlVisible: true,
      rangoControlVisible: true,
      datosControlVisible: true,
      cargando: false,
      permitirActualizacionAuto: false,

      keyLocalidad: 0,
      cargandoExcel: false,
      listaLocalidad: null,

      urlMarca: this.$api.URL + "/marca/obtenerTodosSelect",
      marcas: [],
    };
  },
  mounted() {
    this.mapData.height = window.innerHeight - 50;

    this.$nextTick(() => {
      this.$refs.map.mapObject.zoomControl.remove();
      // seleccionar provincia del usuario
      this.provincia = [];
      this.$usuario().datosEmpleado.detalleProvincia.forEach((prov) => {
        let existe = false;

        for (const provinciaItem of this.provincia) {
            if (provinciaItem.id == prov.provinciaID) {
                existe = true
                break
            }
        }

        if (!existe) {
          this.provincia.push({
            id: prov.provinciaID,
            nombre: prov.provincia,
          });
        }
      });

      // traer localidad de las provincia seleccionadas
      if (this.provincia.length === 1){
        this.provinciaCambiada();
      }

      if(this.permitirActualizacionAuto){
          this.getDatos();
      }
    });
  },
  methods: {
    async redrawMap() {

        //setTimeout(function(){ this.$refs.map.mapObject.invalidateSize() }, 400);

        setTimeout(() => {
            window.dispatchEvent(new Event("resize"));
        }, 100);
    },
    async getDatos() {
      this.cargando = true;

      let url = this.$api.URL + "/puntoVenta/obtenerTodosSimple";
      let separador = "?";

      if (this.provincia != null && this.provincia.length > 0) {
        let provinciaSel = [];
        this.provincia.forEach((prov) => {
          provinciaSel.push({ id: prov.id });
        });

        url =
            url + separador + "detalleProvinciaID=" + JSON.stringify(provinciaSel);
        separador = "&";
      }
      if (this.localidad != null && this.localidad.length > 0) {
        let localidadSel = [];
        this.localidad.forEach((local) => {
          localidadSel.push({ id: local });
        });

        url =
            url +
            separador +
            "detalleLocalidadID=" +
            JSON.stringify(localidadSel);
        separador = "&";
      }

      if (this.marcas != null && this.marcas.length > 0) {
        let marcasSel = [];
        this.marcas.forEach((marca) => {
          marcasSel.push({ id: marca.id });
        });

        url = url + separador + "marca=" + JSON.stringify(marcasSel);
        separador = "&";
      }

      let respuestaApi = await this.$api.get(url, this.$usuarioToken(), 0);

      this.cargando = false;

      if (respuestaApi != null) {
        this.datosRelevamientos = respuestaApi.datos;
        this.markers = [];
        let tmpMarkers = [];

        respuestaApi.datos.forEach((item) => {
          tmpMarkers.push({
            latlng: {
              lat: item.latitud,
              lng: item.longitud,
            },
            id: item.id,
            puntoVenta: item.puntoVenta,
            fecha: item.fecha,
            hora: item.hora,
            compradorSecco: item.compradorSecco,
          });
        });

        this.markers = tmpMarkers;
      }
    },
    getClaseMarcador(item) {
      return L.icon({
        iconUrl: "img/icons/nosecco.png",
        iconSize: [30, 42],
        iconAnchor: [15, 40],
        popupAnchor: [0, -40],
        shadowUrl: "img/icons/shadow.png",
        shadowSize: [47, 42],
        shadowAnchor: [20, 40],
      });
    },
    provinciaCambiada() {
      if (this.provincia != null){
        if (this.provincia.length === 1){
          this.listaLocalidad = [];
          this.localidad = null;

          this.provincia.forEach(async (prov) => {
            let respuestaApi = await this.$api.get(
                this.$api.URL + "/localidad/obtenerTodos?provinciaID=" + prov.id,
                this.$usuarioToken()
            );

            respuestaApi.forEach((localidad) => {
              let existe = false;

              this.listaLocalidad.forEach((localEnArray) => {
                if (localEnArray.id == localidad.id) {
                  existe = true;
                }
              });

              if (!existe) {
                this.listaLocalidad.push(localidad);
              }
            });
          });


        }
      }
    },
  },
  watch: {
    provincia() {
      this.keyLocalidad += 1;
    },
    localidad() {
      if(this.permitirActualizacionAuto){
          this.getDatos();
      }
    },
  },
};
</script>
