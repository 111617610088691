var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _c(
            "maca-datatable",
            {
              attrs: {
                url: _vm.urlTabla,
                params: _vm.paramsTabla,
                actualizar: _vm.actualizarTabla,
                bloquear: _vm.bloquearTabla
              },
              on: {
                "update:actualizar": function($event) {
                  _vm.actualizarTabla = $event
                },
                "update:bloquear": function($event) {
                  _vm.bloquearTabla = $event
                }
              }
            },
            [
              _c(
                "el-table-column",
                {
                  attrs: { label: "Fecha", width: 100 },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$common.formatearFecha(props.row.creado)
                              )
                            }
                          })
                        ]
                      }
                    }
                  ])
                },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      _c("maca-datatable-filtrar-rangofecha", {
                        attrs: { label: "Fecha" },
                        model: {
                          value: _vm.filtroFechas,
                          callback: function($$v) {
                            _vm.filtroFechas = $$v
                          },
                          expression: "filtroFechas"
                        }
                      })
                    ],
                    1
                  )
                ],
                2
              ),
              _c("el-table-column", {
                attrs: { label: "Foto", width: "70px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [
                        _c(
                          "el-link",
                          {
                            attrs: {
                              href: props.row.urlImagen,
                              target: "_blank"
                            }
                          },
                          [
                            _c(
                              "el-image",
                              {
                                staticStyle: { width: "50px", height: "50px" },
                                attrs: {
                                  src: props.row.urlImagen,
                                  fit: "contain"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-slot",
                                    attrs: { slot: "error" },
                                    slot: "error"
                                  },
                                  [_vm._v("Sin Foto")]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c(
                "el-table-column",
                {
                  attrs: { label: "Nombre", width: 150 },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c("span", {
                            domProps: { textContent: _vm._s(props.row.nombre) }
                          })
                        ]
                      }
                    }
                  ])
                },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      _c("maca-datatable-filtrar", {
                        attrs: { label: "Nombre" },
                        model: {
                          value: _vm.filtroNombre,
                          callback: function($$v) {
                            _vm.filtroNombre = $$v
                          },
                          expression: "filtroNombre"
                        }
                      })
                    ],
                    1
                  )
                ],
                2
              ),
              _c("el-table-column", {
                attrs: { label: "Teléfono", width: 120 },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [
                        props.row.duenioTelefono
                          ? _c("span", {
                              domProps: {
                                textContent: _vm._s(props.row.duenioTelefono)
                              }
                            })
                          : _c("span", [_vm._v("-")])
                      ]
                    }
                  }
                ])
              }),
              _c(
                "el-table-column",
                {
                  attrs: { label: "Canal", width: 150 },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(props.row.tipoCanal)
                            }
                          })
                        ]
                      }
                    }
                  ])
                },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      _c("maca-datatable-filtrar-select", {
                        attrs: { label: "Canal", urlSelect: _vm.urlTipoCanal },
                        model: {
                          value: _vm.filtroTipoCanal,
                          callback: function($$v) {
                            _vm.filtroTipoCanal = $$v
                          },
                          expression: "filtroTipoCanal"
                        }
                      })
                    ],
                    1
                  )
                ],
                2
              ),
              _c("el-table-column", {
                attrs: { label: "Empleado" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [
                        _c("span", {
                          domProps: { textContent: _vm._s(props.row.empleado) }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c(
                "el-table-column",
                {
                  attrs: { label: "Provincia" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(props.row.provincia)
                            }
                          })
                        ]
                      }
                    }
                  ])
                },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      _c("maca-datatable-filtrar-select", {
                        attrs: {
                          label: "Provincia",
                          urlSelect: _vm.urlProvincia
                        },
                        model: {
                          value: _vm.filtroProvincia,
                          callback: function($$v) {
                            _vm.filtroProvincia = $$v
                          },
                          expression: "filtroProvincia"
                        }
                      })
                    ],
                    1
                  )
                ],
                2
              ),
              _c("el-table-column", {
                attrs: { label: "Localidad" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [
                        _c("span", {
                          domProps: { textContent: _vm._s(props.row.localidad) }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "Barrio" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [
                        _c("span", {
                          domProps: { textContent: _vm._s(props.row.barrio) }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c(
                "el-table-column",
                {
                  attrs: { label: "Es Secco", width: 110 },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          props.row.compradorSecco
                            ? _c("b", { staticStyle: { color: "#ec2127" } }, [
                                _vm._v("Si")
                              ])
                            : _c("span", [_vm._v("No")])
                        ]
                      }
                    }
                  ])
                },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      _c("maca-datatable-filtrar-sino", {
                        attrs: { label: "Es Secco" },
                        model: {
                          value: _vm.filtroCompradorSecco,
                          callback: function($$v) {
                            _vm.filtroCompradorSecco = $$v
                          },
                          expression: "filtroCompradorSecco"
                        }
                      })
                    ],
                    1
                  )
                ],
                2
              ),
              _c(
                "el-table-column",
                {
                  attrs: { label: "Visitado", width: 130 },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: "Registrar que ya fue visitado",
                                    placement: "bottom-start"
                                  }
                                },
                                [
                                  !props.row.contactarRealizado &&
                                  props.row.contactar
                                    ? _c("el-button", {
                                        staticStyle: { margin: "auto" },
                                        attrs: {
                                          type: "success",
                                          size: "small",
                                          plain: "",
                                          round: "",
                                          icon: "el-icon-check"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.marcarVisitado(
                                              props.row.id
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              props.row.contactarRealizado
                                ? _c("el-button", {
                                    staticStyle: {
                                      margin: "auto",
                                      "font-size": "20px"
                                    },
                                    attrs: {
                                      type: "text",
                                      size: "small",
                                      icon: "el-icon-check"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      _c("maca-datatable-filtrar-visitado", {
                        attrs: { label: "Visitado" },
                        model: {
                          value: _vm.filtroVisitado,
                          callback: function($$v) {
                            _vm.filtroVisitado = $$v
                          },
                          expression: "filtroVisitado"
                        }
                      })
                    ],
                    1
                  )
                ],
                2
              ),
              _c("el-table-column", {
                attrs: { label: "Ver", width: "70" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "success",
                              size: "small",
                              plain: "",
                              round: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.$refs.modalVer.abrir(props.row.id)
                              }
                            }
                          },
                          [_c("i", { staticClass: "el-icon-view" })]
                        )
                      ]
                    }
                  }
                ])
              }),
              !_vm.$usuarioEs.supervisor()
                ? _c("el-table-column", {
                    attrs: { label: "Editar", width: "70" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(props) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "warning",
                                    size: "small",
                                    plain: "",
                                    round: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.$refs.modalModificar.abrir(
                                        props.row.id
                                      )
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "el-icon-edit" })]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3860868514
                    )
                  })
                : _vm._e(),
              !_vm.$usuarioEs.supervisor()
                ? _c("el-table-column", {
                    attrs: { label: "Borrar", width: "70" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(props) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "danger",
                                    size: "small",
                                    plain: "",
                                    round: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.eliminar(props.row.id)
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "el-icon-delete" })]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2934779640
                    )
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c("modal-ver", {
        ref: "modalVer",
        on: {
          "actualizar-tabla": function($event) {
            _vm.actualizarTabla = true
          }
        }
      }),
      _c("modal-modificar", {
        ref: "modalModificar",
        on: {
          "actualizar-tabla": function($event) {
            _vm.actualizarTabla = true
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }