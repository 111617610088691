var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "contenedor-titulo" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "botones" },
          [
            !_vm.$usuarioEs.supervisor()
              ? _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.cargandoExcel,
                        expression: "cargandoExcel"
                      }
                    ],
                    attrs: { type: "default" },
                    on: { click: _vm.descargarExcel }
                  },
                  [_vm._v("Exportar Puntos A Visitar")]
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _c(
        "el-card",
        { attrs: { "body-style": { padding: "20px" } } },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.pestanaActiva,
                callback: function($$v) {
                  _vm.pestanaActiva = $$v
                },
                expression: "pestanaActiva"
              }
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "Tabla", name: "0" } },
                [
                  _c("vista-tabla", {
                    on: {
                      filtroNombreCambiado: function(val) {
                        return (_vm.filtroNombre = val)
                      },
                      filtroTipoCanalCambiado: function(val) {
                        return (_vm.filtroTipoCanal = val)
                      },
                      filtroVisitadoCambiado: function(val) {
                        return (_vm.filtroVisitado = val)
                      },
                      filtroFechasCambiado: function(val) {
                        _vm.filtroDesde = val[0]
                        _vm.filtroHasta = val[1]
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "Mapa", name: "1" } },
                [
                  _c("mapa-puntos-venta", {
                    ref: "mapa",
                    class: { creandoNuevo: _vm.creandoNuevo },
                    staticStyle: { width: "100%", height: "600px" },
                    on: { mapClicked: _vm.mapClicked }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("modal-ver", { ref: "modalVer" }),
      _c("modal-nuevo", {
        ref: "modalNuevo",
        on: { "actualizar-tabla": _vm.getDatos }
      }),
      _c("modal-modificar", {
        ref: "modalModificar",
        on: { "actualizar-tabla": _vm.getDatos }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "titulo" }, [
      _c("h3", [
        _c("i", { staticClass: "el-icon-s-flag" }),
        _vm._v("Puntos de Venta")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }