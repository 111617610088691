<template>
  <div>
    <maca-modal titulo="Modificar Punto de Venta" :impedirClose="impedirClose" ref="modal">
      <el-form :model="form" ref="form" :rules="formRules" label-width="150px" v-loading="cargando">
        <el-form-item label="Nombre" prop="nombre">
          <el-input v-model="form.nombre"></el-input>
        </el-form-item>
        <el-form-item label="Canal" prop="tipoCanal">
          <maca-select-box
              icon="el-icon-folder-opened"
              v-model="form.tipoCanal"
              :url="urlTipoCanal"
          ></maca-select-box>
        </el-form-item>
        <el-form-item label="Provincia" prop="provincia">
          <el-input :disabled="true" v-model="form.provincia"></el-input>
        </el-form-item>
        <el-form-item label="Localidad" prop="localidad">
          <el-input :disabled="true" v-model="form.localidad"></el-input>
        </el-form-item>
        <el-form-item label="Barrio" prop="barrio">
          <el-input v-model="form.barrio"></el-input>
        </el-form-item>
        <el-form-item label="Domicilio" prop="domicilio">
          <el-input :disabled="true" v-model="form.domicilio"></el-input>
        </el-form-item>
        <el-form-item label="Es Secco" prop="compradorSecco">
          <el-checkbox
              v-model="form.compradorSecco"
              :disabled="true"
          ></el-checkbox>
        </el-form-item>
        <el-form-item label="Tipo Extra" prop="tipoExtraPuntoVentas">
          <maca-select-box
            icon="el-icon-s-flag"
            v-model="form.tipoExtraPuntoVentas"
            :url="urltipoExtraPuntoVenta"
            multiple
          ></maca-select-box>
        </el-form-item>
        <el-form-item label="A Visitar" prop="contactar">
          <el-checkbox
            v-model="form.contactar"
            :indeterminate="false"
            :disabled="bloquearContactar"
          ></el-checkbox>
        </el-form-item>
        <el-form-item v-if="form.contactar" label="Contacto Nombre" prop="duenioNombre">
          <el-input maxlength="100" v-model="form.duenioNombre"></el-input>
        </el-form-item>
        <el-form-item v-if="form.contactar" label="Contacto Teléfono" prop="duenioTelefono">
          <el-input-number :max="9999999999" :controls="false" v-model="form.duenioTelefono"></el-input-number>
        </el-form-item>
        <el-form-item v-if="form.contactar" label="Distribuidor" prop="distribuidorMayorista">
          <maca-select-box
            icon="el-icon-folder-opened"
            v-model="form.distribuidorMayorista"
            :url="urlDistribuidorMayorista"
          ></maca-select-box>
        </el-form-item>
        <el-form-item>
          <maca-boton-guardar :onSubmit="onSubmit"></maca-boton-guardar>
        </el-form-item>
      </el-form>
    </maca-modal>
  </div>
</template>

<script>
export default {
  name: "modificar-puntoVenta",
  data() {
    return {
      form: {
        nombre: "",
        provincia: "",
        localidad: "",
        barrio: "",
        domicilio: "",
        compradorSecco: false,
        latitud: "",
        longitud: "",
        tipoCanal: null,
        contactar: false,
        tipoCompraPuntoVenta: null,
        distribuidorMayorista: null,
        duenioNombre: "",
        duenioTelefono: "",
        tipoExtraPuntoVentas: [],
      },
      impedirClose: false,
      cargando: false,
      id: 0,
      bloquearContactar: false,

      urlTipoCanal: this.$api.URL + "/tipoCanal/obtenerTodosSelect",
      urlTipoCompraPuntoVenta:
        this.$api.URL + "/tipoCompraPuntoVenta/obtenerTodosSelect",
      urlDistribuidorMayorista:
        this.$api.URL + "/distribuidorMayorista/obtenerTodosSelect",
      urltipoExtraPuntoVenta:
        this.$api.URL + "/tipoExtraPuntoVenta/obtenerTodosSelect",
    };
  },
  computed: {
    formRules() {
      let result = {
        nombre: [
          {
            required: true,
            message: "Por favor introduzca el nombre.",
            trigger: "change",
          },
        ],
        barrio: [
          {
            required: true,
            message: "Por favor introduzca el barrio.",
            trigger: "change",
          },
        ],
        tipoCanal: [
          {
            required: true,
            message: "Por favor introduzca un canal.",
            trigger: "change",
          },
        ],
        domicilio: [
          {
            required: true,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],
        latitud: [
          {
            required: true,
            message: "Por favor introduzca la latitud.",
            trigger: "change",
          },
        ],
        longitud: [
          {
            required: true,
            message: "Por favor introduzca la longitud.",
            trigger: "change",
          },
        ],
      };

      if (this.form.contactar) {
        result.duenioNombre = [
          {
            required: true,
            message:
              "Por favor introduzca un nombre de contacto para la visita.",
            trigger: "change",
          },
        ];
        result.duenioTelefono = [
          {
            required: true,
            message:
              "Por favor introduzca un número de teléfono para la visita.",
            trigger: "change",
          },
        ];
        result.distribuidorMayorista = [
          {
            required: true,
            message: "Por favor introduzca un distribuidor para la visita.",
            trigger: "change",
          },
        ];
      }

      return result;
    },
  },
  methods: {
    abrir(id) {
      this.$refs.modal.abrir();

      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }

      this.id = id;
      this.getDatos();
    },
    cerrar() {
      this.$refs.modal.cerrar();
    },
    async getDatos() {
      this.cargando = true;

      let respuestaApi = await this.$api.get(
        this.$api.URL + "/puntoVenta/obtenerDatos?puntoVentaID=" + this.id,
        this.$usuarioToken()
      );

      this.cargando = false;

      if (respuestaApi != null) {
        this.form.nombre = respuestaApi.nombre;
        this.form.provincia = respuestaApi.provincia;
        this.form.localidad = respuestaApi.localidad;
        this.form.barrio = respuestaApi.barrio;
        this.form.domicilio = respuestaApi.domicilio;
        this.form.compradorSecco = respuestaApi.compradorSecco === 1;
        this.form.latitud = respuestaApi.latitud;
        this.form.longitud = respuestaApi.longitud;
        this.form.tipoCanal = {
          id: respuestaApi.tipoCanalID,
          nombre: respuestaApi.tipoCanal,
        };
        this.form.tipoCompraPuntoVenta = {
          id: respuestaApi.tipoCompraPuntoVentaID,
          nombre: respuestaApi.tipoCompraPuntoVenta,
        };
        this.form.contactar = respuestaApi.contactar ? true : false;

        // Solo se permite marcar en true o false
        this.bloquearContactar = respuestaApi.contactar ? true : false;

        this.form.duenioNombre = respuestaApi.duenioNombre;
        this.form.duenioTelefono = respuestaApi.duenioTelefono;
        this.$nextTick(() => {
          if (respuestaApi.contactarDistribuidorMayoristaID != null) {
            this.form.distribuidorMayorista = {
              id: respuestaApi.contactarDistribuidorMayoristaID,
              nombre: respuestaApi.contactarDistribuidorMayorista,
            };
          }
        });

        this.form.tipoExtraPuntoVentas = respuestaApi.puntoVentaTipoExtra;
      } else {
        this.cerrar();
      }
    },
    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          afterSubmit();
          return false;
        }

        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;
        afterSubmit();

        if (postOk) {
          this.$emit(this.$eventStrings._actualizarTabla);
          this.cerrar();
        }
      });
    },
    async postApi() {
      // Hacer Post
      let params = {
        puntoVentaID: this.id,
        nombre: this.form.nombre,
        barrio: this.form.barrio,
        domicilio: this.form.domicilio,
        latitud: this.form.latitud,
        longitud: this.form.longitud,
        tipoCanalID: this.form.tipoCanal.id,
        cargaApi: 0,
        compradorSecco: this.form.compradorSecco ? 1 : 0,
        contactar: this.form.contactar ? 1 : 0,
        tipoExtraPuntoVenta: JSON.stringify(this.form.tipoExtraPuntoVentas),
      };

      // convertir arreglo de extras a algo que la
      // api entienda
      let tipoExtraPuntoVentaAPI = [];
      this.form.tipoExtraPuntoVentas.forEach((element) => {
        tipoExtraPuntoVentaAPI.push({
          id: this.id,
          tipoExtraPuntoVentaID: element.id,
        });
      });
      params.tipoExtraPuntoVenta = JSON.stringify(tipoExtraPuntoVentaAPI);

      if (this.form.duenioNombre != "" && this.form.duenioNombre != null) {
        params.duenioNombre = this.form.duenioNombre;
      }
      if (this.form.duenioTelefono != "" && this.form.duenioTelefono != null) {
        params.duenioTelefono = this.form.duenioTelefono.toString();
      }
      if (this.form.distribuidorMayorista != null) {
        params.contactarDistribuidorMayoristaID = this.form.distribuidorMayorista.id;
      }

      let respuestaApi = await this.$api.post(
        this.$api.URL + "/puntoVenta/actualizar",
        params,
        this.$usuarioToken()
      );

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Punto de Venta actualizado con éxito!",
          type: "success",
        });

        return true;
      } else if (respuestaApi.estado === 0) {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0
        });
      } else {
        this.$message({
          message: respuestaApi.mensaje,
          type: "error",
          showClose: true,
          duration: 0
        });
      }

      return false;
    },
  },
};
</script>
